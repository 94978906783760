import React, { useState, useEffect } from 'react';

import { API_URL, authorization_key, base_url } from "../../../config/config";
import axios from 'axios';

import Heading from "../../components/Heading";

const Contact = () => {
  const token = localStorage.getItem("token")
  console.log(token)
  const [success, setSuccess] = useState('');
  const [formData, setFormData] = useState({ name: '', mobile: '', email: '', subject: '', message: '' });
  
  const [errors, setErrors] = useState({});

  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    // console.log(formData);
  }
  // vallidation check
  const validateForm = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }
    if (!formData.subject.trim()) {
      errors.subject = "Subject is required";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Invalid email format";
    }

    if (!formData.mobile.trim()) {
      errors.mobile = "Mobile is required";
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      errors.mobile = "Mobile number should be 10 digits";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };
  const handlePageSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.put(API_URL + 'web/insert-enquiry', formData);
        console.log(response.data.status);
        if (response.data.status) {
          setSuccess(response.data.message);
          setFormData({ name: '', mobile: '', email: '', subject: '', message: '' });

        } else {
          setSuccess(response.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      console.log("Form validation failed");
    }
  };
  return (
    <>
      <Heading title="Contact Us" />
      <div className="container py-4">
        <div className="row mb-5 mb-md-0">
          <div className="col-md-6 col-12">
            <h2>Contact Information</h2>
            <p>Email: </p>
            <p>Phone: </p>
            <p>Address: </p>
          </div>
          <div className="col-md-6 col-12">
            <h2>Enquiry Form</h2>
            <form onSubmit={handlePageSubmit}>
              <div className="form-group">
                <div className="form-label">Name</div>
                <input type="text" name="name" className="form-control" placeholder="Enter Name" onChange={handleInputChange} value={formData.name}></input>
                {errors.name && <div className="error text-danger">{errors.name}</div>}
              </div>
              <div className="form-group">
                <div className="form-label">Mobile</div>
                <input type="text" name="mobile" className="form-control" placeholder="Enter Mobile Number" onChange={handleInputChange} value={formData.mobile}></input>
                {errors.name && <div className="error text-danger">{errors.mobile}</div>}
              </div>
              <div className="form-group">
                <div className="form-label">Email</div>
                <input type="text" name="email" className="form-control" placeholder="Enter Email Id" onChange={handleInputChange} value={formData.email}></input>
                {errors.name && <div className="error text-danger">{errors.email}</div>}
              </div>
              <div className="form-group">
                <div className="form-label">Subject</div>
                <input type="text" name="subject" className="form-control" placeholder="Enter Subject" onChange={handleInputChange} value={formData.subject}></input>
                {errors.name && <div className="error text-danger">{errors.subject}</div>}
              </div>
              <div className="form-group">
                <div className="form-label">Message</div>
                <textarea type="text" name="message" className="form-control" placeholder="Enter message" onChange={handleInputChange} value={formData.message}></textarea>
              </div>
              {success && <div class="alert alert-success" role="alert">{success}</div>}
              <div className="form-group">
                <button className="btn btn-primary" type='submit'>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
