const NoPageAdmin = () => {
  return (
    <div className="container">
      <h1>404 Error! Page Not Found</h1>
      <p>
        The page you are looking for is not available or moved to another
        location.
      </p>
    </div>
  );
};

export default NoPageAdmin;
