import React, { useState, useEffect } from 'react';
import { Link, Navigate, Route, useNavigate } from "react-router-dom";
import axios from 'axios';
import { API_URL, authorization_key, base_url } from "../../../config/config";
import Spinner from 'react-bootstrap/Spinner';

import Heading from "../../components/Heading";
const Login = () => {
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [uname, setUname] = useState('');
  const [otp, setOTP] = useState('');
  const navigate = useNavigate();

  const [token, setToken] = useState('');
  
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const tokens = localStorage.getItem("token");
  const login_data = localStorage.getItem("login_data");

  // vallidation check
  const validateForm = () => {
    const errors = {};

    if (!uname.trim()) {
      errors.name = "Name is required";
    }

    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email format";
    }

    if (!mobile.trim()) {
      errors.mobile = "Mobile is required";
    } else if (!/^\d{10}$/.test(mobile)) {
      errors.mobile = "Mobile number should be 10 digits";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Perform form submission or other actions
      try {
        const response = await axios.post(API_URL + 'web/signup', { mobile: mobile,uname:uname,email:email,role:3 });
        // console.log(response.status);
  
        if (response.data.status == 1) {
          localStorage.setItem('login_data', mobile);
          setMessage('<div class="alert alert-success" role="alert">'+response.data.message+'</div>');
          setIsLoading(false);
        } else {
          setMessage('<div class="alert alert-warning" role="alert">'+response.data.message+'</div>');
          setIsLoading(false);
        }
        // Redirect to dashboard or any other page
      } catch (error) {
        console.error(error);
      }
    } else {
      console.log("Form validation failed");
    }
    
  };
  const handleOTPSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(API_URL + 'web/otpverify', { mobile: login_data, otp: otp });
      // console.log(response.data.token);

      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.removeItem("login_data");
        setToken(response.data.token);
        setMessage(response.data.message);
        navigate("/contact");
      } else {
        console.log(response.data.token);
      }
      // Redirect to dashboard or any other page
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    localStorage.removeItem("login_data");
    if (tokens) {
      console.log(tokens)
      navigate("/property-listing");
    }
  }, [/* dependency array */]);
  return (
    <>
      <Heading title="Login" />
      <div className='container'>
        <div className='row justify-content-center'>
          {!login_data &&
            <div className='col-12 col-lg-4'>
              <form onSubmit={handleSubmit} className="py-5 mt-5">
                <label>Name:</label>
                <input type="text" value={uname} onChange={(e) => setUname(e.target.value)} className="form-control" />
                {errors.name && <div className="error text-danger">{errors.name}</div>}
                <br />
                <label>Email:</label>
                <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" />
                {errors.email && <div className="error text-danger">{errors.email}</div>}
                <br />
                <label>Mobile Number:</label>
                <input type="text" value={mobile} onChange={(e) => setMobile(e.target.value)} className="form-control" />
                {errors.mobile && <div className="error text-danger">{errors.mobile}</div>}
                <br />
                {message && <div dangerouslySetInnerHTML={{ __html: message }}></div>}
                <button className="btn btn-primary" type="submit" onClick={() => setIsLoading(true)}>Login {isLoading ? (<Spinner animation="border" size="sm"/> ) : ('')}</button>
              </form>
            </div>
          }
          {login_data &&
            <div className='col-12 col-lg-4'>
              <form onSubmit={handleOTPSubmit} className="py-5 mt-5">
                <label>Enter OTP:</label>
                <input type="text" value={otp} onChange={(e) => setOTP(e.target.value)} className="form-control" />
                <br />
                {message && <div dangerouslySetInnerHTML={{ __html: message }}></div>}
                <button className="btn btn-primary" type="submit" onClick={() => setIsLoading(true)}>Verify OTP {isLoading ? (<Spinner animation="border" size="sm"/> ) : ('')}</button>
              </form>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default Login;
