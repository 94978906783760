import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./frontend/screens/Home";
import Signin from "./frontend/screens/Signin";
// import $ from 'jquery';



import Layout from "./frontend/Layout/Layout";
import Signup from "./frontend/screens/Signup";
import Contact from "./frontend/screens/Contact";
import PropertyListing from "./frontend/screens/PropertyListing";
import PropertyListingSingle from "./frontend/screens/PropertyListing/PropertySingle";
import NoPage from "./frontend/screens/NoPage";
import Page from "./frontend/screens/Page";
import Services from "./frontend/screens/Services";
import ServicesSingle from "./frontend/screens/Services/ServicesSingle";
import Events from "./frontend/screens/Events";
import EventSingle from "./frontend/screens/Events/EventSingle";
import Blogs from "./frontend/screens/Blogs";
import BlogsSingle from "./frontend/screens/Blogs/BlogsSingle";

import NoPageAdmin from "./backend/screens/NoPageAdmin";
import Login from "./backend/screens/Login";
import AdminLayout from "./backend/AdminLayout";
import Dashboard from "./backend/screens/Dashboard";
import Pages from "./backend/screens/Pages";
import User from "./backend/screens/User";
import AdminProfile from "./backend/screens/Profile";
import Property from "./backend/screens/Property";
import BlogsAdmin from "./backend/screens/Blogs";
import EventsAdmin from "./backend/screens/Events";
import ServicesAdmin from "./backend/screens/Services";
import AdminChangePassword from "./backend/screens/ChangePassword";
import AdminEnquiry from "./backend/screens/Enquiry";



const App = () => {
  // console.log(process.env.REACT_APP_ADMIN_URL+' testing data');
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="signin" element={<Signin />} />
          <Route path="signup" element={<Signup />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about-us" element={<Page />} />
          <Route path="privacy-policy" element={<Page />} />
          <Route path="terms-and-conditions" element={<Page />} />
          <Route path="refund-policy" element={<Page />} />
          <Route path="services" element={<Services />} />
          <Route path="services/:slug" element={<ServicesSingle />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="blogs/:slug" element={<BlogsSingle />} />
          <Route path="events" element={<Events />} />
          <Route path="events/:slug" element={<EventSingle />} />
          <Route path="property-listing/:type?/:slug?" element={<PropertyListing />} />
          <Route path="property-listing-single/:id" element={<PropertyListingSingle />} />
          <Route path="*" element={<NoPage />} />
        </Route>

        <Route path="/admin-panel/login" element={<Login />} />
        <Route path="/admin-panel" element={<AdminLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="p/:slug" element={<Pages />} />
          <Route path="user" element={<User />} />
          <Route path="Property" element={<Property />} />
          <Route path="blogs" element={<BlogsAdmin />} />
          <Route path="events" element={<EventsAdmin />} />
          <Route path="services" element={<ServicesAdmin />} />
          <Route path="profile" element={<AdminProfile />} />
          <Route path="change-password" element={<AdminChangePassword />} />
          <Route path="enquiry" element={<AdminEnquiry />} />
          <Route path="*" element={<NoPageAdmin />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
