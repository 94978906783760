import React, { useState } from 'react';
import { API_URL, authorization_key, base_url } from "../../../config/config";
import axios from 'axios';
const LiveSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  
  const handleSearch = async (event) => {
    const { value } = event.target;
    setSearchTerm(value);

    // Perform the API call to fetch search results
    const response = await axios.get(API_URL + `web/footerdata?query=${value}`);

    if (response.data.data.AreaName) {
      setSearchResults(response.data.data.AreaName);
    }
    
    console.log(response.data.data.AreaName);

  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch} className='form-control'
      />

      <ul>
        {searchResults.map((result) => (
          <li key={result.area_id} 
          >{result.area_name}</li>
        ))}
      </ul>
    </div>
  );
};

export default LiveSearch;
