import { Link } from "react-router-dom";

import locationIcon from '../../../assets/images/location.svg';
const EventCard = (props) => {
    return (
        <div className="col-12 col-md-2 col-lg-3 mb-4">
            <div className="bg-white">
                <img src={props.image} alt={props.name} className="w-100"/>
                <div className="nc-p-12  text-center">
                    <h3 className="text-black nc-event-name mb-2">{props.name}</h3>
                    <p className="mb-2 text-black nc-event-description">{props.description}</p>
                    <Link to={'events/'+props.link} className="nc-property-common d-flex justify-content-center">View Detail</Link>
                </div>
            </div>
        </div>
    );
  };
  
  export default EventCard;
  