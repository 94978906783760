import { Link, Navigate, Route, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API_URL, authorization_key, base_url } from "../../../config/config";

const DeleteData = ({ apiUrl, blog_id, onDataDeleted }) => {
  const tokens = localStorage.getItem("admin_token")
  const [message, setMessage] = useState('');
  const ACCESS_TOKEN = tokens;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `${ACCESS_TOKEN}`
    };
  const handleDelete = async () => {
    try {

      // Make an HTTP POST request to the backend server
      axios.delete(API_URL + 'admin/'+apiUrl+'/delete/'+blog_id, { headers })
        .then((response) => {
          if (response.data.status) {

            setMessage('<div className="alert alert-success" role="alert">' + response.data.message + '</div>');
            onDataDeleted(response.data.status);

            const timer = setTimeout(() => {
              setMessage('');
            }, 3000);
          } else {
            setMessage('<div className="alert alert-warning" role="alert">' + response.data.message + '</div>');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <button className="btn p-0" onClick={handleDelete}><i className="feather icon-trash"></i></button>
  );
};

export default DeleteData;
