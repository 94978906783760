import { Link, Navigate, Route,useNavigate,Outlet} from "react-router-dom";
import React, { useState ,useEffect} from 'react';

import Navigation from "../components/Navigation";
import Login from "../screens/Login";

import "../../assets/admin/css/style.css";
import '../../assets/admin/fonts/fontawesome/css/fontawesome-all.min.css';
import '../../assets/admin/plugins/animation/css/animate.min.css';

// import '../../assets/admin/plugins/bootstrap/js/bootstrap.min.js';
import jQuery from 'jquery';
window.jQuery = jQuery;


const AdminLayout = () => {
  const navigate = useNavigate();

  const [token, setToken] = useState('');

  const tokens = localStorage.getItem("admin_token");
  useEffect(() => {
    if(tokens){
      // console.log(tokens)
      // navigate("/admin-panel");
    } else {
      // navigate("/admin-panel/login");

    }
  }, [/* dependency array */]);

  return (
    <>
    {tokens ? (
      <div>
        <Navigation />
      <main className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
                <div className="main-body">
                    <div className="page-wrapper">
                      <Outlet />
                    </div>
                </div>
            </div>
          </div>
          </div>
      </main>
      </div>
    ) : (
    <Login/>
    )}
    </>
  );
};

export default AdminLayout;
