import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL, authorization_key, base_url } from "../../../config/config";

import locationIcon from '../../../assets/images/location.svg';
const PropertyCard = (props) => {
    
    
    return (
        <>
        <div className="col-12 col-md-2 col-lg-3 mb-4">
            <div className="bg-white">
                <div style={{ height: "250px" }}>
                <img src={props.image} alt={props.name} className="w-100 h-100"/>
                </div>
                <p className="nc-property-badge">{props.label}</p>
                <div className="nc-p-12">
                    <h3 className="text-black nc-property-name mb-2">{props.name}</h3>
                    <p className="mb-2 text-black nc-property-common"> {props.description}</p>
                    <p className="mb-2 text-dark nc-property-common">Rs. {props.price}/Month</p>
                    {/* <div>
                        <div className="d-flex justify-content-between nc-property-specification">
                            <div>Area (sqft)</div>
                            <div>920</div>
                        </div>
                        <div className="d-flex justify-content-between nc-property-specification">
                            <div>Bedrooms</div>
                            <div>2</div>
                        </div>
                        
                        <div className="d-flex justify-content-between nc-property-specification">
                            <div>Bathrooms</div>
                            <div>1</div>
                        </div>
                    </div> */}
                    <Link to={'property-listing-single/'+props.slug} className="nc-property-common d-flex justify-content-center">View Detail</Link>
                </div>
            </div>
        </div>
         </>
    );
  };
  
  export default PropertyCard;
  