import React, { useState, useEffect } from 'react';
import { Link, Navigate, Route, useNavigate, useParams, useLocation } from "react-router-dom";

import { API_URL, authorization_key, base_url } from "../../../config/config";
import axios from 'axios';

import Heading from "../../components/Heading";

const Page = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [pageData, setPageData] = useState({});
  const slug = currentPath.replace(/\//g, '');
  const handleGetPage = async (e) => {
    // e.preventDefault();

    try {
      const response = await axios.get(API_URL + 'web/pages/' + slug);
      // console.log(response.data.token);

      if (response.data) {
        setPageData(response.data.data);
      } else {
        // console.log(response.data.token);
      }
      // Redirect to dashboard or any other page
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    handleGetPage(slug)
  }, [slug]);

  return (
    <>
      <Heading title={pageData.page_title} />
      <div className="container py-4">
        <div className="row mb-5 mb-md-0">
          <p>{pageData.page_description}</p>
        </div>
      </div>
    </>
  );
};

export default Page;
