import { Link, Navigate, Route, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API_URL, authorization_key, base_url } from "../../../config/config";



const Enquiry = () => {
    const buttonEventAddEditModalRef = useRef(null);
    const buttonEventmageAddEditModalRef = useRef(null);

    let { id } = useParams();
    const [formData, setFormData] = useState({ enquiry_title: '' });
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        const newState = { ...formData };
        for (let key in newState) {
            delete newState[key];
        }
        setFormData(newState);
        setIsOpen(false);
    };

    function handleInputChange(event) {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        // console.log(formData);
    }


    const navigate = useNavigate();
    const [token, setToken] = useState(localStorage.getItem("admin_token"));
    const [enquiryData, setEnquiryData] = useState([]);
    const [message, setMessage] = useState('');

    const tokens = localStorage.getItem("admin_token")

    function decodeHtmlEntities(text) {
        const parser = new DOMParser();
        const decodedString = parser.parseFromString(`<!doctype html><body>${text}`, 'text/html').body.textContent;
        return decodedString;
    }
    const ACCESS_TOKEN = tokens;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `${ACCESS_TOKEN}`
    };


    const handleObjectClick = (enquiry_id) => {
        const selected = enquiryData.find((obj) => obj.enquiry_id === enquiry_id);
        // console.log(selected);
        setFormData(selected);
        // openModal()
    };

    
    const handleEnquiry = async (e) => {
        // e.preventDefault();

        try {
            const response = await axios.get(API_URL + 'admin/enquiry', { headers });
            // console.log(response.data.token);

            if (response.data) {
                setEnquiryData(response.data.data);
                setFormData(response.data.data);
                // console.log(response.data.data);

            } else {
                // console.log(response.data.token);
            }
            // Redirect to dashboard or any other page
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        handleEnquiry()
    }, [id]);

    return (
        <>
            <div className="">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="page-header">
                                <div className="page-block">
                                    <div className="row align-items-center">
                                        <div className="col-md-9">
                                            <div className="page-header-title">
                                                <h5 className="m-b-10">Enquiry</h5>
                                            </div>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={'../'} ><i className="feather icon-home"></i></Link>
                                                </li>
                                                <li className="breadcrumb-item">Enquiry</li>
                                                <li className="breadcrumb-item">View</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-body">
                                <div className="page-wrapper ">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5>Enquiry</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="table-responsive">
                                                                <table className="table table-hover">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>Name</th>
                                                                            <th>Mobile</th>
                                                                            <th>Email</th>
                                                                            <th>Subject</th>
                                                                            <th>Message</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {enquiryData.map((data, index) => (
                                                                            <tr key={data.enquiry_id} >
                                                                                <th scope="row">{index + 1}</th>
                                                                                <td>{data.enquiry_name}</td>
                                                                                <td>{data.enquiry_mobile}</td>
                                                                                <td>{data.enquiry_email}</td>
                                                                                <td>{data.enquiry_subject}</td>
                                                                                <td>{data.enquiry_message}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Enquiry;
