const SectionTitle = (props) => {
  return (
    <div className="section-title py-4">
        <div className="text-center mb-2">{props.title}</div>
        <p className="pb-0">{props.description}</p>
    </div>
  );
};

export default SectionTitle;
