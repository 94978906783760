import { Link, Navigate, Route, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect, Modal } from 'react';
import axios from 'axios';
import { API_URL, authorization_key, base_url } from "../../../config/config";

const User = () => {


    const [pageData, setPageData] = useState([]);
    const [success, setSuccess] = useState('');

    const tokens = localStorage.getItem("admin_token");
    const ACCESS_TOKEN = tokens;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `${ACCESS_TOKEN}`
    };
    const [formData, setFormData] = useState({ page_title: '', page_tagline: '', page_description: '' });
    const handleGetUser = async (e) => {
        // e.preventDefault();

        try {
            const response = await axios.get(API_URL + 'admin/users', { headers });
            // console.log(response.data.token);

            if (response.data) {
                setPageData(response.data.data);
                setFormData(response.data.data);
                console.log(response.data.data);

            } else {
                // console.log(response.data.token);
            }
            // Redirect to dashboard or any other page
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        handleGetUser()
    }, []);
    const [setIsOpen, setShowModal] = useState(false);


    return (
        <>
            <section className="pcoded-main-container ms-0">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="page-header">
                                <div className="page-block">
                                    <div className="row align-items-center">
                                        <div className="col-md-12">
                                            <div className="page-header-title">
                                                <h5 className="m-b-10">User Master</h5>
                                            </div>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={'../'} ><i className="feather icon-home"></i></Link>
                                                </li>
                                                <li className="breadcrumb-item">Master</li>
                                                <li className="breadcrumb-item">User</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5>View User</h5>
                                                    {/* <span className="d-block m-t-5">User Table <code>table</code> inside table element</span> */}
                                                </div>
                                                <div className="card-block table-border-style">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Name</th>
                                                                    <th>Mobile</th>
                                                                    <th>Email</th>
                                                                    <th>Address</th>
                                                                    <th>Category</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {pageData.map((data, index) => (
                                                                    <tr>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>{data.user_name}</td>
                                                                        <td> {data.user_mobile}</td>
                                                                        <td>{data.user_email}</td>
                                                                        <td>{data.user_address}</td>
                                                                        <td>{data.role_name}</td>
                                                                        <td>
                                                                            <button className="btn" onClick={() => setShowModal(true)}><i className="feather icon-edit"></i></button>
                                                                            <button className="btn"><i className="feather icon-trash"></i></button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="position-relative">
                <div className={`modal ${setIsOpen ? 'show d-block' : ''}`}>
                    <div className="modal-content">
                        <h2>fdsafdsa</h2>
                        <p>fdsafdsafd</p>
                        <button onClick={() => setShowModal(false)} s>Close Modal</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default User;
