import { Link, Navigate, Route, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API_URL, authorization_key, base_url } from "../../../config/config";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// import Modal from 'react-modal';
// import 'react-modal/style.css';

import { upload } from "@testing-library/user-event/dist/upload";
const customStyles = {
    content: {
    },
    overlay: { zIndex: 300 }
};

const Events = () => {

    const buttonEventAddEditModalRef = useRef(null);
    const buttonEventmageAddEditModalRef = useRef(null);

    let { id } = useParams();
    const [formData, setFormData] = useState({ event_title: '' });
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        const newState = { ...formData };
        for (let key in newState) {
            delete newState[key];
        }
        setFormData(newState);
        setIsOpen(false);
    };

    function handleInputChange(event) {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        // console.log(formData);
    }


    const navigate = useNavigate();
    const [token, setToken] = useState(localStorage.getItem("admin_token"));
    const [pageData, setPageData] = useState([]);
    const [message, setMessage] = useState('');

    const tokens = localStorage.getItem("admin_token")

    function decodeHtmlEntities(text) {
        const parser = new DOMParser();
        const decodedString = parser.parseFromString(`<!doctype html><body>${text}`, 'text/html').body.textContent;
        return decodedString;
    }
    const ACCESS_TOKEN = tokens;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `${ACCESS_TOKEN}`
    };


    const handleObjectClick = (event_id) => {
        const selected = pageData.find((obj) => obj.event_id === event_id);
        // console.log(selected);
        setFormData(selected);
        // openModal()
    };
    const handleEventSubmit = async (e) => {
        e.preventDefault();
        const ACCESS_TOKEN = tokens;
        // console.log(formData);
        try {
            const response = await axios.put(API_URL + 'admin/event/' + formData.event_id, formData, { headers });
            // console.log(response.data.status);
            if (response.data.status) {

                setMessage('<div class="alert alert-success" role="alert">' + response.data.message + '</div>');


                const timer = setTimeout(() => {
                    buttonEventAddEditModalRef.current.click();
                    setMessage('')
                    closeModal();
                    handleEvent()
                }, 3000);
            } else {
                setMessage('<div class="alert alert-warning" role="alert">' + response.data.message + '</div>');
            }
        } catch (error) {
            setMessage('<div class="alert alert-warning" role="alert">' + error + '</div>');
            // console.error(error);
        }
    };

    // image upload
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const handleUploadImage = async (e) => {
        e.preventDefault();
        const ACCESS_TOKEN = tokens;
        console.log(selectedFile);
        // console.log(formData);
        try {
            const formDatas = new FormData();
            formDatas.append('image', selectedFile);
            formDatas.append('event_id', formData.event_id);

            // Make an HTTP POST request to the backend server
            axios.post(API_URL + 'admin/event/upload', formDatas, formData, { headers })
                .then((response) => {
                    if (response.data.status) {

                        setMessage('<div class="alert alert-success" role="alert">' + response.data.message + '</div>');


                        const timer = setTimeout(() => {
                            buttonEventmageAddEditModalRef.current.click();
                            setMessage('');
                            closeModal();
                            handleEvent()
                            console.log(formData);
                        }, 3000);
                    } else {
                        setMessage('<div class="alert alert-warning" role="alert">' + response.data.message + '</div>');
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } catch (error) {
            console.error(error);
        }
    };
    const handleEvent = async (e) => {
        // e.preventDefault();

        try {
            const response = await axios.get(API_URL + 'admin/event', { headers });
            // console.log(response.data.token);

            if (response.data) {
                setPageData(response.data.data);
                // setFormData(response.data.data);
                // console.log(response.data.data);

            } else {
                // console.log(response.data.token);
            }
            // Redirect to dashboard or any other page
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        handleEvent()
    }, [id]);

    return (
        <>
            <div className="">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="page-header">
                                <div className="page-block">
                                    <div className="row align-items-center">
                                        <div className="col-md-9">
                                            <div className="page-header-title">
                                                <h5 className="m-b-10">Events</h5>
                                            </div>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={'../'} ><i className="feather icon-home"></i></Link>
                                                </li>
                                                <li className="breadcrumb-item">Events</li>
                                                <li className="breadcrumb-item">View</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-3 text-right">
                                            <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className="feather icon-plus"></i> Add New Events</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-body">
                                <div className="page-wrapper ">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5>Events</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="table-responsive">
                                                                <table className="table table-hover">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>Image</th>
                                                                            <th>Events Name</th>
                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {pageData.map((data, index) => (
                                                                            <tr key={data.event_id} >
                                                                                <th scope="row">{index + 1}</th>
                                                                                <td><img style={{ width: "100px" }} src={data.image_url} /></td>
                                                                                <td> {data.event_title}</td>
                                                                                <td>
                                                                                    <button className="btn p-0" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => handleObjectClick(data.event_id)}><i className="feather icon-edit"></i></button>
                                                                                    <button className="btn p-0" data-bs-toggle="modal" data-bs-target="#staticUploadImage" onClick={() => handleObjectClick(data.event_id)}><i className="feather icon-image"></i></button>
                                                                                    {/* <button className="btn" onClick={openModal}><i className="feather icon-trash"></i></button> */}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">
                                {formData.event_id ? 'Update ' + formData.event_title : 'Add Events'}
                            </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal} ref={buttonEventAddEditModalRef}></button>
                        </div>
                        <div class="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <form onSubmit={handleEventSubmit}>
                                        <input type="hidden" name="id" onChange={handleInputChange} value={formData.event_id} />
                                        <div className="form-group">
                                            <label for="EventsName">Events Name</label>
                                            <input type="text" className="form-control" id="EventsName" name="event_title" placeholder="Enter Events  Name" onChange={handleInputChange} value={formData.event_title} autocomplete="on" />
                                        </div>
                                        <div className="form-group">
                                            <label for="EventsShortDescription">Page Description</label>
                                            <textarea className="form-control" name="event_short_description" id="EventsShortDescription" rows="7" placeholder="Enter Short Description" onChange={handleInputChange} value={formData.event_short_description}></textarea>
                                        </div>

                                        {message && <div dangerouslySetInnerHTML={{ __html: message }}></div>}

                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={closeModal}>Close</button>
                            <button type="submit" className="btn btn-primary" onClick={handleEventSubmit}>{formData.event_id ? 'Update' : 'Add'}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="staticUploadImage" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">
                                {formData.event_id ? 'Update ' + formData.event_title : 'Add Events'}
                            </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal} ref={buttonEventmageAddEditModalRef}></button>
                        </div>
                        <div class="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <form onSubmit={handleUploadImage} encType="multipart/form-data">
                                        <input type="hidden" name="id" onChange={handleInputChange} value={formData.event_id} />
                                        <div className="form-group">
                                            <label for="EventsImage">Image</label>
                                            <input type="file" className="form-control" id="EventsImage" name="event_image" onChange={handleFileChange} />
                                        </div>

                                        {message && <div dangerouslySetInnerHTML={{ __html: message }}></div>}

                                    </form>
                                    {/* <form onSubmit={handleUploadImage} encType="multipart/form-data">
                                        <input type="file" name="image" onChange={handleFileChange} />
                                        <button type="submit">Upload</button>
                                        </form> */}
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={closeModal}>Close</button>
                            <button type="submit" className="btn btn-primary" onClick={handleUploadImage}>{formData.event_id ? 'Update' : 'Add'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Events;
