import React, { useState, useEffect } from 'react';
import { Link, Navigate, Route, useNavigate } from "react-router-dom";
import axios from 'axios';
import { API_URL, authorization_key, base_url } from "../../../config/config";
import Spinner from 'react-bootstrap/Spinner';

import Heading from "../../components/Heading";
const Login = () => {
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');
  const [otp, setOTP] = useState('');
  const navigate = useNavigate();

  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const tokens = localStorage.getItem("token");
  const login_data = localStorage.getItem("login_data");

  // vallidation check
  const validateForm = () => {
    const errors = {};
    if (!mobile.trim()) {
      errors.mobile = "Mobile is required";
    } else if (!/^\d{10}$/.test(mobile)) {
      errors.mobile = "Mobile number should be 10 digits";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const validateOTPForm = () => {
    const errors = {};
    if (!otp.trim()) {
      errors.otp = "OTP is required";
    } else if (!/^\d{6}$/.test(otp)) {
      errors.otp = "OTP should be 6 digits";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post(API_URL + 'web/login', { mobile: mobile });
        console.log(response.status);

        if (response.data.status == 1) {
          localStorage.setItem('login_data', mobile);
          setMessage('<div class="alert alert-success" role="alert">' + response.data.message + '</div>');
          setIsLoading(false);
        } else {
          setMessage('<div class="alert alert-warning" role="alert">' + response.data.message + '</div>');
          console.log(response.data.message);
          setIsLoading(false);
        }
        // Redirect to dashboard or any other page
      } catch (error) {
        console.error(error);
      }
    }
  };
  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    if (validateOTPForm()) {
      try {
        const response = await axios.post(API_URL + 'web/otpverify', { mobile: login_data, otp: otp });
        console.log(response.data);

        if (response.data.token) {
          localStorage.setItem('token', response.data.token);
          localStorage.removeItem("login_data");
          setToken(response.data.token);
          setMessage('<div class="alert alert-success" role="alert">' + response.data.message + '</div>');
          setIsLoading(false);
          navigate("/contact");
        } else {
          setMessage('<div class="alert alert-warning" role="alert">' + response.data.message + '</div>');
          setIsLoading(false);
          console.log(response.data.token);
        }
        // Redirect to dashboard or any other page
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (tokens) {
      console.log(tokens)
      navigate("/property-listing");
    }
  }, [/* dependency array */]);
  return (
    <>
      <Heading title="Login" />
      <div className='container'>
        <div className='row justify-content-center'>
          {!login_data &&
            <div className='col-12 col-lg-4'>
              <form onSubmit={handleSubmit} className="py-5 mt-5">
                <label>Mobile Number:</label>
                <input type="text" value={mobile} onChange={(e) => setMobile(e.target.value)} className="form-control" minlength="10" maxlength="10" />
                {errors.mobile && <div className="error text-danger">{errors.mobile}</div>}
                <br />
                {message && <div dangerouslySetInnerHTML={{ __html: message }}></div>}
                <button className="btn btn-primary" onClick={() => setIsLoading(true)} type="submit">Login {isLoading ? (<Spinner animation="border" size="sm" />) : ('')}</button>
              </form>
            </div>
          }
          {login_data &&
            <div className='col-12 col-lg-4'>
              <form onSubmit={handleOTPSubmit} className="py-5 mt-5">
                <label>Enter OTP:</label>
                <input type="text" value={otp} onChange={(e) => setOTP(e.target.value)} className="form-control" minlength="6" maxlength="6" />
                {errors.otp && <div className="error text-danger">{errors.otp}</div>}
                <br />
                {message && <div dangerouslySetInnerHTML={{ __html: message }}></div>}
                <button className="btn btn-primary" type="submit" onClick={() => setIsLoading(true)}>Verify OTP {isLoading ? (<Spinner animation="border" size="sm" />) : ('')}</button>
              </form>
            </div>
          }
        </div>
      </div>

    </>
  );
};

export default Login;
