import { Helmet } from 'react-helmet';
import { Routes, Route, useParams } from 'react-router-dom';
import Heading from "../../components/Heading";
const PropertySingle = () => {
    // const slug = this.props.params.id
    console.log('fdsaf')
    let { id } = useParams();
    
  return (
    <>
        <Helmet>
            <title>{id}</title>
        </Helmet> 
        <Heading title={id} />
      <div className="container py-4">
        <div className="row">
        </div>
      </div>
    </>
  );
};

export default PropertySingle;
