import React, { useState ,useEffect} from 'react';
import { Link, Navigate, Route,useNavigate} from "react-router-dom";
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';

import { API_URL, authorization_key, base_url, admin_url } from "../../../config/config";
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const login_data = localStorage.getItem("login_data");

  const tokenExpiration = localStorage.getItem('tokenExpiration');



  const [token, setToken] = useState('');

  const tokens = localStorage.getItem("admin_token");

  // vallidation check
  const validateForm = () => {
    const errors = {};

    
    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email format";
    }

    if (!password.trim()) {
      errors.password = "Password is required";
    } 

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
    try {
      const response = await axios.post(API_URL+'admin/login', { username:email, password:password });
      // console.log(response.data.token);
      if (response.data.token) {
        localStorage.setItem('admin_token', response.data.token);
        setToken(response.data.token);
        setMessage('<div class="alert alert-success" role="alert">' + response.data.message + '</div>');
        setIsLoading(false);
        localStorage.setItem('tokenExpiration', Date.now() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds


        setIsLoading(false);
        navigate("/admin-panel");
      } else {
        setMessage('<div class="alert alert-danger" role="alert">' + response.data.message + '</div>');
        setIsLoading(false);
      }

      // Redirect to dashboard or any other page
    } catch (error) {
      console.error(error);
    }
    }
  };
  useEffect(() => {
    // Checking if the token has expired
    if (Date.now() > tokenExpiration) {
      // Token has expired, handle the expiration accordingly (e.g., redirect to login)
      localStorage.removeItem("admin_token");
      localStorage.removeItem("tokenExpiration");
    } else {
      if(tokens){
        // console.log(tokens)
        navigate("/admin-panel");
      }
    }

    
  }, [/* dependency array */]);
  return (
    <>
      <div className="auth-wrapper">
        <div className="auth-content">
            <div className="auth-bg">
                <span className="r"></span>
                <span className="r s"></span>
                <span className="r s"></span>
                <span className="r"></span>
            </div>
            <div className="card">
                <div className="card-body text-center">
                  <form onSubmit={handleSubmit}>  
                    <div className="mb-4">
                        <i className="feather icon-unlock auth-icon"></i>
                    </div>
                    <h3 className="mb-4">Login</h3>
                    <div className="input-group mb-3 flex-column">
                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control w-100" placeholder="Enter Username"/>
                        {errors.email && <div className="error text-start text-danger">{errors.email}</div>}
                    </div>
                    <div className="input-group mb-3 flex-column">
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control w-100" placeholder="password"/>
                        {errors.password && <div className="error text-start text-danger">{errors.password}</div>}
                    </div>
                    <div className="form-group text-left">
                        <div className="checkbox checkbox-fill d-inline">
                            <input type="checkbox" name="checkbox-fill-1" id="checkbox-fill-a1" checked=""/>
                            <label for="checkbox-fill-a1" className="cr"> Save Details</label>
                        </div>
                    </div>
                    {message && <div dangerouslySetInnerHTML={{ __html: message }}></div>}
                    <button className="btn btn-primary shadow-2 mb-4" type="submit" onClick={() => setIsLoading(true)}>Login {isLoading ? (<Spinner animation="border" size="sm" />) : ('')}</button>
                    {/* <p className="mb-2 text-muted">Forgot password? <a href="auth-reset-password.html">Reset</a></p>
                    <p className="mb-0 text-muted">Don’t have an account? <a href="auth-signup.html">Signup</a></p> */}
                  </form>
                </div>
            </div>
        </div>
    </div>
    </>
  );
};

export default Login;
