import { Outlet } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";




import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import '../../assets/css/style.css';



const Layout = ({ children, isPending }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  

  useEffect(() => {
    function handleScroll() {
      setScrollPosition(window.scrollY);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <Navigation bg={scrollPosition > 100 ? 'nc-custom-navbar' : ''} />
      <div>
        <div className="container-fluid">
        </div>
      </div>
      <div className="container-fluid px-0">
        <main>
          <Outlet />
        </main>
      </div>
      
      <Footer/>
    </>
  );
};

export default Layout;
