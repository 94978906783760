import React, { useState, useEffect } from 'react';
import { Link, Navigate, Route, useNavigate, useParams, useLocation } from "react-router-dom";

import { API_URL, authorization_key, base_url } from "../../../config/config";
import axios from 'axios';

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

import LiveSearch from '../../components/LiveSearch';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import right_banner from '../../../assets/images/banner-right.png';

const PropertyListing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const [filters, setFilters] = useState({
    type: queryParams.get('type') || '',
    bhk: queryParams.get('bhk') || '',
    orderby: queryParams.get('orderby') || '',
  });

  useEffect(() => {
    // Update URL query params when filters change
    const newSearchParams = new URLSearchParams();

    if (filters.type) {
      newSearchParams.set('type', filters.type);
    }

    if (filters.bhk) {
      newSearchParams.set('bhk', filters.bhk);
    }
    if (filters.orderby) {
      newSearchParams.set('orderby', filters.orderby);
      handleGetProperty()
    }

    navigate(`?${newSearchParams.toString()}`);
  }, [filters, navigate]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  const [propertyData, setPropertyData] = useState([]);
  const handleGetProperty = async (e) => {
    // e.preventDefault();

    try {
      const { bhk, type, orderby } = filters;

      const queryParams = new URLSearchParams();
      if (bhk) {
        queryParams.append('bhk', bhk);
      }
      if (type) {
        queryParams.append('type', type);
      }
      if (orderby) {
        queryParams.append('orderby', orderby);
      }
      const response = await axios.get(API_URL + 'web/property/?' + queryParams.toString());

      if (response.data) {
        setPropertyData(response.data.data);
      }
      // Redirect to dashboard or any other page
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetProperty()
  }, []);


  
  return (
    <>
      {/* <div className="nc-head-section d-flex align-items-center">
        <div className="container-fluid d-flex justify-content-between">
          <h1 className="text-white mb-0 nc-head-section-title">Property Listing</h1>
          <ul className="breadcrumb justify-content-center  text-white pb-0 mb-0">
            <li><Link to={'/'}>Home / &nbsp;</Link></li>
            <li>Property Listing</li>
          </ul>
        </div>
      </div> */}
      <div className="container-fluid mb-5 mb-md-0 bg-white py-2 mt-4">
        <div className="row align-items-center mt-5">
          <form className="row align-items-center">
            <div className='col-2'>
              {/* <LiveSearch /> */}
            </div>
            <div className='col-2'>
              <select className='form-select' name='type' value={filters.type} onChange={handleFilterChange}>
                <option value="">Property Type</option>
                <option value="Apartment">Apartment</option>
                <option value="Independent House">Independent House</option>
                <option value="Independent Floor">Independent Floor</option>
                <option value="Villa">Villa</option>
              </select>
            </div>
            <div className='col-2'>
              <select className='form-select' name='bhk' value={filters.bhk} onChange={handleFilterChange}>
                <option value="">BHK Type</option>
                <option value="1 BHK">1 BHK</option>
                <option value="2 BHK">2 BHK</option>
                <option value="3 BHK">3 BHK</option>
                <option value="4 BHK">4 BHK</option>
              </select>
            </div>
            <div className='col-3'>
              <button className='btn btn-primary mb-0' type='button' onClick={handleGetProperty}>Seach</button>
            </div>
          </form>
        </div>
      </div>
      <section className="services-section section position-relative py-4">
        <div className="container mb-5 mb-md-0">

          <div className="row justify-content-center">
            <div className='col-8'>
              <div className='row py-2'>
                <div className='col-6'>
                  <ul className="breadcrumb text-dark pb-0 mb-0 pt-0">
                    <li ><Link to={'/'} className='text-dark'>Home / &nbsp;</Link></li>
                    <li>Property</li>
                  </ul>
                </div>
                <div className='col-6 text-right'><strong className='fw-bold'>Last Updated:</strong> June 06, 2023</div>
              </div>
              <div className='row pt-2 pb-1 align-items-center'>
                <div className='col-7'>
                  <strong className='fw-bold'>Showing </strong>1 - 20 of {propertyData.length}<br />
                  Properties for Sale in Sector 24 Rohini,Sector 11 Rohini
                </div>
                <div className='col-5 text-right'>
                  <strong className='fw-bold'>Sort by:</strong>
                  <select className='ms-2' name='orderby' value={filters.orderby} onChange={handleFilterChange} >
                    <option value="">Relevance</option>
                    <option value="Price (Asc)">Price (Asc)</option>
                    <option value="Price (Desc)">Price (Desc)</option>
                    <option value="Last Updated">Last Updated</option>
                    <option value="Date Added">Date Added</option>
                  </select>
                </div>
              </div>
              {propertyData.map(data => (
                <div className='card rounded-2 shadow-1 mb-3' key={data.property_id}>
                  <div className='col-12'>
                    <div className='row'>
                      <div className='col-3'>
                        <span className="badge bg-success position-absolute mt-4 ms-2 nc-pointer-cursor nc-h3-12 nc-z-index-2"><i className='fa fa-check me-1'></i>Verified</span>
                        <Swiper
                          // modules={[Navigation, Pagination]}
                          spaceBetween={50}
                          slidesPerView={1}
                          // navigation
                          pagination={{ clickable: true }}
                          scrollbar={{ draggable: true }}
                        // onSwiper={(swiper) => console.log(swiper)}
                        // onSlideChange={() => console.log('slide change')}
                        >
                          {data.img_data.map(im_data => (
                            <SwiperSlide className='border my-3' style={{ height: "150px" }} key={im_data.image_id}>
                              <img src={im_data.image_urls} className="w-100 h-100" /></SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                      <div className='col-9 py-3'>
                        <span className="badge bg-danger position-absolute end-0 me-4 nc-pointer-cursor nc-h3-14 text-capitalize">{data.property_label}</span>
                        <p className='fw-bold nc-h3-16 text-dark mb-2'><i className='fa fa-rupee-sign'></i>{data.property_price} / Month</p>
                        <h2 className='fw-bold nc-h3-16  mb-2'>{data.property_name}</h2>
                        <h2 className='fw-bold nc-h3-16  mb-2'>{data.property_bhk}</h2>
                        <h2 className='fw-bold nc-h3-16  mb-2'>Property Type: {data.property_type}</h2>
                        <p className='fw-bold nc-h3-12  mb-2'>{data.property_short_description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='col-4'>
              <div className='card rounded-3 p-5'>
                <img src={right_banner} className="w-100 h-100"/>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default PropertyListing;
