import Heading from "../../components/Heading";
import Footer from "../../components/Footer";
const NoPage = () => {
  return (
    <>
    <Heading title="404 No Page Found !" />
    <div className="container py-5 text-center">
      <h1>404 Error! Page Not Found</h1>
      <p>
        The page you are looking for is not available or moved to another
        location.
      </p>
    </div>
    </>
  );
};

export default NoPage;
