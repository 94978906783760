import React, { useState, useEffect } from 'react';
import { Link, Navigate, Route, useNavigate, useParams, useLocation } from "react-router-dom";

import { API_URL, authorization_key, base_url } from "../../../config/config";
import axios from 'axios';

import Heading from "../../components/Heading";

import EventCard from "../../components/EventCard";

import pimage from '../../../assets/images/property-image.svg';
const Events = () => {
  
  const [eventData, setEventData] = useState({event_title:''});
  let { slug } = useParams();
  const handleGetEvent = async (e) => {
    // e.preventDefault();

    try {
      const response = await axios.get(API_URL + 'web/events/'+slug);
      // console.log(response.data.token);

      if (response.data) {
        setEventData(response.data.data[0]);
        console.log(eventData);
      } else {
        // console.log(response.data.token);
      }
      // Redirect to dashboard or any other page
    } catch (error) {
      console.error(error);
    }
  };
  document.title = eventData.event_title ? eventData.event_title+' | Events' : ' Events';
  
  useEffect(() => {
    handleGetEvent()
  }, []);
  return (
    <>
      <Heading title={eventData.event_title} />
      <section className="services-section section position-relative py-4">
            <div className="container">
                <div className="row justify-content-center">
                {eventData.event_description}    
                </div>
            </div>
        </section>
    </>
  );
};

export default Events;
