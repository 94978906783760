import { Link } from "react-router-dom";

const PropertyCard = (props) => {
    return (
        <div className="nc-head-section d-flex align-items-center mt-5">
            <div className="container text-center mt-2">
                <h1 className="text-white mb-0 nc-head-section-title">{props.title}</h1>
                <ul className="breadcrumb justify-content-center  text-white pb-0 mb-0">
                    <li><Link to={'/'}>Home / &nbsp;</Link></li>
                    <li>{props.title}</li>
                </ul>
            </div>
        </div>
    );
  };
  
  export default PropertyCard;
  