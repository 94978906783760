import { Link, Navigate, Route, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API_URL, authorization_key, base_url } from "../../../config/config";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// import Modal from 'react-modal';
// import 'react-modal/style.css';

import { upload } from "@testing-library/user-event/dist/upload";
const customStyles = {
    content: {
    },
    overlay: { zIndex: 300 }
};

const Profile = () => {

    const buttonPropertyAddEditModalRef = useRef(null);
    const buttonPropertyImageAddEditModalRef = useRef(null);

    let { id } = useParams();
    const [formData, setFormData] = useState({ property_name: '' });
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        const newState = { ...formData };
        for (let key in newState) {
            delete newState[key];
        }
        setFormData(newState);
        setIsOpen(false);
    };

    function handleInputChange(event) {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        // console.log(formData);
    }


    const navigate = useNavigate();
    const [token, setToken] = useState(localStorage.getItem("admin_token"));
    const [pageData, setPageData] = useState([]);
    const [message, setMessage] = useState('');

    const tokens = localStorage.getItem("admin_token")

    function decodeHtmlEntities(text) {
        const parser = new DOMParser();
        const decodedString = parser.parseFromString(`<!doctype html><body>${text}`, 'text/html').body.textContent;
        return decodedString;
    }
    const ACCESS_TOKEN = tokens;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `${ACCESS_TOKEN}`
    };


    const handleObjectClick = (property_id) => {
        const selected = pageData.find((obj) => obj.property_id === property_id);
        // console.log(selected);
        setFormData(selected);
        // openModal()
    };
    const handlePropertySubmit = async (e) => {
        e.preventDefault();
        const ACCESS_TOKEN = tokens;
        // console.log(formData);
        try {
            const response = await axios.put(API_URL + 'admin/property/' + formData.property_id, formData, { headers });
            // console.log(response.data.status);
            if (response.data.status) {

                setMessage('<div class="alert alert-success" role="alert">' + response.data.message + '</div>');


                const timer = setTimeout(() => {
                    buttonPropertyAddEditModalRef.current.click();
                    setMessage('')
                    closeModal();
                    handleProperty()
                }, 3000);
            } else {
                setMessage('<div class="alert alert-warning" role="alert">' + response.data.message + '</div>');
            }
        } catch (error) {
            setMessage('<div class="alert alert-warning" role="alert">' + error + '</div>');
            // console.error(error);
        }
    };

    // image upload
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const handleUploadImage = async (e) => {
        e.preventDefault();
        const ACCESS_TOKEN = tokens;
        console.log(selectedFile);
        // console.log(formData);
        try {
            const formDatas = new FormData();
            formDatas.append('image', selectedFile);
            formDatas.append('property_id', formData.property_id);

            // Make an HTTP POST request to the backend server
            axios.post(API_URL + 'admin/upload', formDatas, formData, { headers })
                .then((response) => {
                    if (response.data.status) {

                        setMessage('<div class="alert alert-success" role="alert">' + response.data.message + '</div>');


                        const timer = setTimeout(() => {
                            buttonPropertyImageAddEditModalRef.current.click();
                            setMessage('');
                            closeModal();
                            handleProperty()
                            console.log(formData);
                        }, 3000);
                    } else {
                        setMessage('<div class="alert alert-warning" role="alert">' + response.data.message + '</div>');
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } catch (error) {
            console.error(error);
        }
    };
    const handleProperty = async (e) => {
        // e.preventDefault();

        try {
            const response = await axios.get(API_URL + 'admin/property', { headers });
            // console.log(response.data.token);

            if (response.data) {
                setPageData(response.data.data);
                setFormData(response.data.data);
                // console.log(response.data.data);

            } else {
                // console.log(response.data.token);
            }
            // Redirect to dashboard or any other page
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        handleProperty()
    }, [id]);

    return (
        <>
            <div className="">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="page-header">
                                <div className="page-block">
                                    <div className="row align-items-center">
                                        <div className="col-md-9">
                                            <div className="page-header-title">
                                                <h5 className="m-b-10">Property</h5>
                                            </div>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={'../'} ><i className="feather icon-home"></i></Link>
                                                </li>
                                                <li className="breadcrumb-item">Property</li>
                                                <li className="breadcrumb-item">View</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-3 text-right">
                                            <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className="feather icon-plus"></i> Add New Property</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-body">
                                <div className="page-wrapper ">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5>Property</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="table-responsive">
                                                                <table className="table table-hover">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>Image</th>
                                                                            <th>Property Name</th>
                                                                            <th>Label</th>
                                                                            {/* <th>Added By</th>
                                                                            <th>Type</th> */}
                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {pageData.map((data, index) => (
                                                                            <tr key={data.property_id} >
                                                                                <th scope="row">{index + 1}</th>
                                                                                <td><img style={{ width: "100px" }} src={data.image_url} /></td>
                                                                                <td> {data.property_name}</td>
                                                                                <td>{data.property_label}</td>
                                                                                {/* <td>@mdo</td> */}
                                                                                {/* <td>@mdo</td> */}
                                                                                <td>
                                                                                    <button className="btn p-0" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => handleObjectClick(data.property_id)}><i className="feather icon-edit"></i></button>
                                                                                    <button className="btn p-0" data-bs-toggle="modal" data-bs-target="#staticUploadImage" onClick={() => handleObjectClick(data.property_id)}><i className="feather icon-image"></i></button>
                                                                                    {/* <button className="btn" onClick={openModal}><i className="feather icon-trash"></i></button> */}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">
                                {formData.property_id ? 'Update ' + formData.property_name : 'Add Property'}
                            </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal} ref={buttonPropertyAddEditModalRef}></button>
                        </div>
                        <div class="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <form onSubmit={handlePropertySubmit}>
                                        <input type="hidden" name="id" onChange={handleInputChange} value={formData.property_id} />
                                        <div className="form-group">
                                            <label for="PropertyName">Property Name</label>
                                            <input type="text" className="form-control" id="PropertyName" name="property_name" placeholder="Enter Property  Name" onChange={handleInputChange} value={formData.property_name} autocomplete="on" />
                                        </div>
                                        <div className="form-group">
                                            <label for="PropertyName">Property Label</label>
                                            <select className='form-select' name="property_bhk" onChange={handleInputChange} value={formData.property_bhk}>
                                                <option value=" ">BHK Type</option>
                                                <option value="1 BHK">1 BHK</option>
                                                <option value="2 BHK">2 BHK</option>
                                                <option value="3 BHK">3 BHK</option>
                                                <option value="4 BHK">4 BHK</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label for="PropertyName">Property Type</label>
                                            <select className='form-select' name="property_type" onChange={handleInputChange} value={formData.property_type}>
                                                <option value=" ">Property Type</option>
                                                <option value="Apartment">Apartment</option>
                                                <option value="Independent House">Independent House</option>
                                                <option value="Independent Floor">Independent Floor</option>
                                                <option value="Villa">Villa</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label for="PropertyPrice">Property Rent Price</label>
                                            <input type="text" className="form-control" id="PropertyPrice" name="property_price" placeholder="Enter Property Rent Price" onChange={handleInputChange} value={formData.property_price} autocomplete="on" />
                                        </div>
                                        <div className="form-group">
                                            <label for="PropertyPrice">Property BHK</label>
                                            <input type="text" className="form-control" id="PropertyPrice" name="property_price" placeholder="Enter Property Rent Price" onChange={handleInputChange} value={formData.property_price} autocomplete="on" />
                                        </div>
                                        <div className="form-group">
                                            <label for="PropertyShortDescription">Page Description</label>
                                            <textarea className="form-control" name="property_short_description" id="PropertyShortDescription" rows="7" placeholder="Enter Short Description" onChange={handleInputChange} value={formData.property_short_description}></textarea>
                                        </div>

                                        {message && <div dangerouslySetInnerHTML={{ __html: message }}></div>}

                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={closeModal}>Close</button>
                            <button type="submit" className="btn btn-primary" onClick={handlePropertySubmit}>{formData.property_id ? 'Update' : 'Add'}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="staticUploadImage" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">
                                {formData.property_id ? 'Update ' + formData.property_name : 'Add Property'}
                            </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal} ref={buttonPropertyImageAddEditModalRef}></button>
                        </div>
                        <div class="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <form onSubmit={handleUploadImage} encType="multipart/form-data">
                                        <input type="hidden" name="id" onChange={handleInputChange} value={formData.property_id} />
                                        <div className="form-group">
                                            <label for="PropertyImage">Image</label>
                                            <input type="file" className="form-control" id="PropertyImage" name="property_image" onChange={handleFileChange} />
                                        </div>

                                        {message && <div dangerouslySetInnerHTML={{ __html: message }}></div>}

                                    </form>
                                    {/* <form onSubmit={handleUploadImage} encType="multipart/form-data">
                                        <input type="file" name="image" onChange={handleFileChange} />
                                        <button type="submit">Upload</button>
                                        </form> */}
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={closeModal}>Close</button>
                            <button type="submit" className="btn btn-primary" onClick={handleUploadImage}>{formData.property_id ? 'Update' : 'Add'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;
