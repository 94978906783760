import { Link, Navigate, Route, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL, authorization_key, base_url } from "../../../config/config";



import SectionTitle from "../../components/SectionTitle";
import PropertyCard from "../../components/PropertyCard";
import EventCard from "../../components/EventCard";

import bgslider from '../../../assets/images/home-bg.svg';
import pbanner from '../../../assets/images/p-banner.svg';
import pimage from '../../../assets/images/property-image.svg';

import feature1 from '../../../assets/images/feature/1.svg';
import feature2 from '../../../assets/images/feature/2.svg';
import feature3 from '../../../assets/images/feature/3.svg';
import feature4 from '../../../assets/images/feature/4.svg';


import newsletterImage from '../../../assets/images/newsletter.svg';

const divStyle = {
    backgroundImage: 'url(' + bgslider + ')',
    backgroundRepeat: "no-repeat",
    width: '100%',
    backgroundSize: 'cover',
    height: "calc(100vh - 0px)"
};
const Home = () => {
    const numbers_property = [1, 2, 3, 4, 5, 6, 7, 8];
    const [servicesData, setServiceData] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [success, setSuccess] = useState('');
    const handlePageSubmit = async (e) => {
        e.preventDefault();
    };
    const handleGetHomepageData = async (e) => {
        try {
            const response = await axios.get(API_URL + 'web/homepage');

            if (response.data.data.service_data) {
                setServiceData(response.data.data.service_data);
            } 
            if (response.data.data.service_data) {
                setEventData(response.data.data.event_data);
            } 
            // Redirect to dashboard or any other page
        } catch (error) {
            console.error(error);
        }
    };


    
    const [query, setQuery] = useState("");
    const [results, setResults] = useState([]);
    const [propertyData, setPropertyData] = useState([]);

    // the API endpoint to fetch search results
    const searchAPI = async (query) => {
        const response = await fetch(API_URL + `web/area/search?q=${query}`);
        const data = await response.json();
        setResults(data);
    }
    // handle the input change event and call the searchAPI function
    const handleInputChange = (event) => {
        const newQuery = event.target.value;
        setQuery(newQuery);
        searchAPI(newQuery);
    }
    const handleGetProperty = async (e) => {
        // e.preventDefault();

        try {
            const response = await axios.get(API_URL + 'web/property/');
            // console.log(response.data.token);

            if (response.data) {
                setPropertyData(response.data.data);
                console.log(propertyData);
            } else {
                // console.log(response.data.token);
            }
            // Redirect to dashboard or any other page
        } catch (error) {
            console.error(error);
        }
    };
    const handlePageTitle = (title) => {
        document.title = title.toUpperCase();
      };
    useEffect(() => {
        handleGetHomepageData()
        handleGetProperty()
    }, []);
    return (
        <>
            <section className="home-banner" style={divStyle}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="content d-flex justify-content-center flex-column text-white text-center">
                                <p className="mb-3 nc-banner-title text-white">We help you build</p>
                                <p className="mb-4 nc-banner-title text-primary">your dream</p>
                                <p className="ml-auto mr-auto nc-banner-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s....</p>
                            </div>
                        </div>
                    </div>
                    <div className="row d-none d-md-block">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <form className="d-flex justify-content-evenly">
                                        <div className="form-group mb-0">
                                            <input type="text" name="category" className="form-control" placeholder="Location" onChange={handleInputChange} value={query} />
                                            <ul>
                                                {results.map((result) => (
                                                    <li key={result.id}>{result.title}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="form-group mb-0 px-2">
                                            <input type="text" name="category" className="form-control" placeholder="Category" />
                                        </div>
                                        <div className="form-group mb-0 px-2">
                                            <input type="text" name="category" className="form-control" placeholder="Type" />
                                        </div>
                                        <div className="form-group mb-0 px-2">
                                            <input type="text" name="category" className="form-control" placeholder="Label" />
                                        </div>
                                        <div className="form-group mb-0">
                                            <button className="btn btn-primary">Go</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-section section position-relative py-4 mt-5">
                <div className="container">
                    <SectionTitle title="services" description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s...." />
                    <div className="row justify-content-center">
                        {servicesData.map(data => (
                            <div className="col">
                                <Link to={'services/' + data.service_slug} onClick={() => handlePageTitle(data.service_title)}>
                                    <div className="align-items-center card d-flex justify-content-center p-4 border service-item">
                                        <img className="h-100 mb-3" style={{ width: 84 }} src={data.image_url} alt={data.service_title} />
                                        {/* {data.service_image} */}
                                        <p className="services-name">{data.service_title}</p>
                                    </div>
                                </Link>
                            </div>
                        ))}

                    </div>
                </div>
            </section>
            <section className="py-4">
                <img className="w-100" src={pbanner} alt="#a+" />
            </section>
            <section className="services-section section position-relative py-4">
                <div className="container">
                    <SectionTitle title="Property" description="This stunning modern apartment is located in the heart of the city. With spacious rooms, beautiful city views, and convenient access to amenities, it offers the perfect urban living experience. Don't miss out on this opportunity!" />
                    <div className="row justify-content-center">
                        {propertyData.map(data => (
                            <PropertyCard image={data.image_url} name={data.property_name} slug={data.property_slug} description={data.property_short_description} price={data.property_price}/>
                        ))}
                    </div>
                </div>
            </section>
            <section className="services-section section position-relative py-4">
                <div className="container">
                    <SectionTitle title="how it works?" description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s...." />
                    <div className="row justify-content-center">
                        <div className="col-md6 col-lg-4 mb-5">
                            <div className="service-box bg-white h-100 p-4">
                                <div className="box bg-warning">
                                    <span></span>
                                </div>
                                <h3 className="my-3 text-black font-weight-bold">Test Name</h3>
                                <p className="mb-4 text-black"> This is testing data for description</p>
                                <Link to="'#'">
                                    Learn More <i className="icon-arrow-right2"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-section section position-relative py-4">
                <div className="container">
                    <SectionTitle title="events" description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s...." />
                    <div className="row justify-content-center">
                        {eventData.map(data => (
                            <EventCard image={data.image_url} name={data.event_title} description={data.event_description} link={data.event_slug}  />
                        ))}
                    </div>
                </div>
            </section>
            <section className="services-section section position-relative pt-4 pb-5">
                <div className="container">
                    <SectionTitle title="feature collections" description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s...." />
                    <div className="row justify-content-center">
                        <div className="col-md-5 d-flex align-items-center justify-content-center text-white" >
                            <div className="align-items-center d-flex flex-column justify-content-center text-center text-white nc-feature-section-item py-5" style={{ backgroundImage: 'url(' + feature1 + ')' }}>
                                <p className="nc-feature-section-item-title">Ready to Move</p>
                                <button className="btn btn-primary">91 Properties</button>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="row mb-4">
                                <div className="col-md-6 d-flex align-items-center justify-content-center text-white">
                                    <div className="align-items-center d-flex flex-column justify-content-center text-center text-white nc-feature-section-item py-5" style={{ backgroundImage: 'url(' + feature2 + ')' }}>
                                        <p className="nc-feature-section-item-title">Ready to Move</p>
                                        <button className="btn btn-primary">91 Properties</button>
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex align-items-center justify-content-center text-white" >
                                    <div className="align-items-center d-flex flex-column justify-content-center text-center text-white nc-feature-section-item py-5" style={{ backgroundImage: 'url(' + feature3 + ')' }}>
                                        <p className="nc-feature-section-item-title">Ready to Move</p>
                                        <button className="btn btn-primary">91 Properties</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 d-flex align-items-center justify-content-center text-white">
                                    <div className="align-items-center d-flex flex-column justify-content-center text-center text-white nc-feature-section-item py-5" style={{ backgroundImage: 'url(' + feature4 + ')' }}>
                                        <p className="nc-feature-section-item-title">Ready to Move</p>
                                        <button className="btn btn-primary">91 Properties</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="nc-newsletter-section section position-relative nc-mt-10">
                <img src={newsletterImage} alt="" className="position-absolute bottom-0 start-0 w-100" />
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col col-lg-7 d-none d-md-block">

                        </div>
                        <div className="col-lg-5 col-12 d-flex align-items-center">
                            <div>
                                <p className="nc-newsletter-title text-white">Get Our Monthly Newsletter</p>
                                <p className="nc-newsletter-description text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                                <div className="d-flex">
                                    <input type={'text'} name="news" placeholder="Input your emaill address in here" className="form-control rounded-0 opacity-75" />
                                    <button className="btn mb-0 rounded-0 bg-white">submit</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
};

export default Home;
