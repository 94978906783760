import React, { useState, useEffect } from 'react';

import { API_URL, authorization_key, base_url } from "../../../config/config";
import axios from 'axios';

import Heading from "../../components/Heading";

const Services = (slug) => {
  const token = localStorage.getItem("token")
  console.log(slug)
  const [success, setSuccess] = useState('');
  const [formData, setFormData] = useState({ name: '', mobile: '', email: '',subject: '', message: '' });
  
  
  return (
    <>
      <Heading title="Services" />
      <div className="container py-4">
        <div className="row mb-5 mb-md-0">
          
        </div>
      </div>
    </>
  );
};

export default Services;
