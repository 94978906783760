import React, { useState, useEffect } from 'react';
import { Link, Navigate, Route, useNavigate } from "react-router-dom";
import axios from 'axios';

import { API_URL, authorization_key, base_url } from "../../../config/config";

import { Sidebar, Menu, MenuItem, SubMenu, ProSidebarProvider } from 'react-pro-sidebar';

import * as actionTypes from "../store/actions";
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';

const Navigation = () => {
    const [isToggle, setIsToggle] = useState(false);

    let headerClass = ['navbar', 'pcoded-header', 'navbar-expand-lg'];
    // if (this.props.headerFixedLayout) {
    //     headerClass = [...headerClass, 'headerpos-fixed'];
    // }

    let navClass = [
        'pcoded-navbar',
    ];

    let toggleClass = ['mobile-menu'];
    if (isToggle) {
        toggleClass = [...toggleClass, 'on'];
        navClass = [...navClass, 'mob-open']
    }

    const navigate = useNavigate();
    const [token, setToken] = useState(localStorage.getItem("admin_token"));
    const [navigationData, setNavigationData] = useState([]);
    const tokens = localStorage.getItem("admin_token")
    const handleLogout = () => {
        localStorage.removeItem("admin_token");
        setToken(null);
        navigate("/admin-panel");
    };

    const handleGetRole = async (e) => {
        // e.preventDefault();

        try {
            const ACCESS_TOKEN = tokens;

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${ACCESS_TOKEN}`
            };
            const response = await axios.get(`${API_URL}` + 'admin/role_user', { headers });
            if (response.status == 200) {
                if (response.data.status == 0) {
                    handleLogout()
                }
                if (response.data.status == 1) {
                    setNavigationData(response.data.data);
                }
            }
        } catch (error) {
            // console.error(error);
        }
    };


    useEffect(() => {
        handleGetRole()

        // localStorage.removeItem("admin_token");
    }, [/* dependency array */]);


    const groupedData = navigationData.reduce((acc, item) => {
        const key = item.module_group;
        // console.log(key);
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(item);
        return acc;
    }, {});

    // dropdown logic start
    const [dropdowns, setDropdowns] = useState({
        dropdown1: false,
        dropdown2: false,
        locationMasterDesktop: false
        // Add more dropdown states as needed
    });
    const toggleDropdown = (dropdownName) => {
        setDropdowns((prevState) => ({
            ...prevState,
            [dropdownName]: !prevState[dropdownName],
        }));
    };
    // dropdown logic end
    return (
        <>
            <div className="loader-bg">
                <div className="loader-track">
                    <div className="loader-fill"></div>
                </div>
            </div>
            <header className={headerClass.join(' ') + 'navbar pcoded-header navbar-expand-lg navbar-light w-100 ms-0'}>
                <div className="m-header">
                    <a className={toggleClass.join(' ')} id="mobile-collapse1" href="#" onClick={() => isToggle ? setIsToggle(false) : setIsToggle(true)}><span /></a>

                    <a href="admin-admin/" className="b-brand">
                        <div className="b-bg">
                            <i className="feather icon-trending-up"></i>
                        </div>
                        <span className="b-title">No Commission</span>
                    </a>
                </div>
                <a className="mobile-menu" id="mobile-header" href="#">
                    <i className="feather icon-more-horizontal"></i>
                </a>
                <div className="collapse navbar-collapse">
                    <ul className="navbar-nav mr-auto">
                        <li><a href="#" className="full-screen"><i className="feather icon-maximize"></i></a></li>
                        <li className="nav-item dropdown">
                            <a className="dropdown-toggle" href="#" data-toggle="dropdown">Dropdown</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <div className="main-search">
                                <div className="input-group">
                                    <input type="text" id="m-search" className="form-control" placeholder="Search . . ." />
                                    <a href="#" className="input-group-append search-close">
                                        <i className="feather icon-x input-group-text"></i>
                                    </a>
                                    <span className="input-group-append search-btn btn btn-primary">
                                        <i className="feather icon-search input-group-text"></i>
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul className="navbar-nav ml-auto">
                        <li>
                            <div className="dropdown">
                                <a className="dropdown-toggle" href="#" data-toggle="dropdown"><i className="icon feather icon-bell"></i></a>
                                <div className="dropdown-menu dropdown-menu-right notification">
                                    <div className="noti-head">
                                        <h6 className="d-inline-block m-b-0">Notifications</h6>
                                        <div className="float-right">
                                            <a href="#" className="m-r-10">mark as read</a>
                                            <a href="#">clear all</a>
                                        </div>
                                    </div>
                                    <ul className="noti-body">
                                        <li className="n-title">
                                            <p className="m-b-0">NEW</p>
                                        </li>
                                        <li className="notification">
                                            <div className="media">
                                                <img className="img-radius" src="/" alt="Generic placeholder image" />
                                                <div className="media-body">
                                                    <p><strong>John Doe</strong><span className="n-time text-muted"><i className="icon feather icon-clock m-r-10"></i>30 min</span></p>
                                                    <p>New ticket Added</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="n-title">
                                            <p className="m-b-0">EARLIER</p>
                                        </li>
                                        <li className="notification">
                                            <div className="media">
                                                <img className="img-radius" src="/" alt="Generic placeholder image" />
                                                <div className="media-body">
                                                    <p><strong>Joseph William</strong><span className="n-time text-muted"><i className="icon feather icon-clock m-r-10"></i>30 min</span></p>
                                                    <p>Prchace New Theme and make payment</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="notification">
                                            <div className="media">
                                                <img className="img-radius" src="/" alt="Generic placeholder image" />
                                                <div className="media-body">
                                                    <p><strong>Sara Soudein</strong><span className="n-time text-muted"><i className="icon feather icon-clock m-r-10"></i>30 min</span></p>
                                                    <p>currently login</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="noti-footer">
                                        <a href="#">show all</a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="dropdown drp-user">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                    <i className="icon feather icon-settings"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right profile-notification">
                                    <div className="pro-head">
                                        <img src="/" className="img-radius" alt="User-Profile-Image" />
                                        <span>John Doe</span>
                                        <a href="auth-signin.html" className="dud-logout" title="Logout">
                                            <i className="feather icon-log-out"></i>
                                        </a>
                                    </div>
                                    <ul className="pro-body">
                                        <li><a href="#" className="dropdown-item"><i className="feather icon-settings"></i> Settings</a></li>
                                        <li><a href="#" className="dropdown-item"><i className="feather icon-user"></i> Profile</a></li>
                                        <li><a href="message.html" className="dropdown-item"><i className="feather icon-mail"></i> My Messages</a></li>
                                        <li><a href="#" className="dropdown-item" onClick={handleLogout}><i className="feather icon-lock"></i> Logout</a></li>

                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </header>

            <nav className={navClass.join(' ')}>
                <div className="navbar-wrapper">
                    <div className="navbar-brand header-logo">
                        <a href="admin-panel" className="b-brand">
                            <div className="b-bg">
                                <i className="feather icon-trending-up"></i>
                            </div>
                            <span className="b-title">No Commission</span>
                        </a>
                        <a className="mobile-menu" id="mobile-collapse" href="#"><span></span></a>
                    </div>
                    <div className="navbar-content scroll-div">
                        <ul className="nav pcoded-inner-navbar">
                            <li className="nav-item pcoded-menu-caption">
                                <label>Dashboard</label>
                            </li>
                            <li className="nav-item">
                                <Link to="/admin-panel" className="nav-link">
                                    <span className="pcoded-micon">
                                        <i className="feather icon-home"></i>
                                    </span>
                                    <span className="pcoded-mtext">Dashboard</span>
                                </Link>
                            </li>
                            <li className="nav-item pcoded-menu-caption">
                                <label>Master</label>
                            </li>
                            <li className="nav-item">
                                <Link to="/admin-panel/user" className="nav-link">
                                    <span className="pcoded-micon">
                                        <i className="feather icon-user"></i>
                                    </span>
                                    <span className="pcoded-mtext">User</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/admin-panel/events" className="nav-link">
                                    <span className="pcoded-micon">
                                        <i className="feather icon-home"></i>
                                    </span>
                                    <span className="pcoded-mtext">Events</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/admin-panel/services" className="nav-link">
                                    <span className="pcoded-micon">
                                        <i className="feather icon-home"></i>
                                    </span>
                                    <span className="pcoded-mtext">Services</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/admin-panel/blogs" className="nav-link">
                                    <span className="pcoded-micon">
                                        <i className="feather icon-home"></i>
                                    </span>
                                    <span className="pcoded-mtext">Blogs</span>
                                </Link>
                            </li>
                            <li className={(dropdowns.locationMasterDesktop ? 'active pcoded-trigger ' : ' ') + ' nav-item pcoded-hasmenu'}>
                                <a href="#!" className={(dropdowns.locationMasterDesktop ? 'active' : ' ') + ' nav-link'} onMouseDown={() => toggleDropdown('locationMasterDesktop')}><span className="pcoded-micon"><i className="feather icon-box"></i></span><span className="pcoded-mtext">Location Master</span></a>
                                <ul className="pcoded-submenu">
                                    <li className={(dropdowns.dropdown2 ? 'active pcoded-trigger ' : ' ') + ' nav-item pcoded-hasmenu'}>
                                        <a href="#" className={(dropdowns.dropdown2 ? 'active' : ' ') + ' nav-link'} onMouseDown={() => toggleDropdown('dropdown2')}>Drodown 2</a>
                                        <ul className="pcoded-submenu">
                                            <li className=""><a href="bc_button.html" className="">D2</a></li>
                                            <li className=""><a href="bc_button.html" className="">D22</a></li>
                                        </ul>
                                    </li>
                                    <li className="">
                                        <a href="bc_badges.html" className="">Badges</a></li>
                                    <li className=""><a href="bc_breadcrumb-pagination.html" className="">Breadcrumb & paggination</a></li>
                                </ul>
                            </li>

                            <li className="nav-item pcoded-menu-caption">
                                <label>Property</label>
                            </li>
                            <li className="nav-item">
                                <Link to="/admin-panel/property" className="nav-link">
                                    <span className="pcoded-micon">
                                        <i className="feather icon-file-text"></i>
                                    </span>
                                    <span className="pcoded-mtext">Property</span>
                                </Link>
                            </li>

                            <li className="nav-item pcoded-menu-caption">
                                <label>Pages</label>
                            </li>
                            <li data-username="form elements advance componant validation masking wizard picker select" className="nav-item">
                                <Link to="/admin-panel/p/about-us" className="nav-link">
                                    <span className="pcoded-micon">
                                        <i className="feather icon-file-text"></i>
                                    </span>
                                    <span className="pcoded-mtext">About Us</span>
                                </Link>
                            </li>
                            <li data-username="form elements advance componant validation masking wizard picker select" className="nav-item">
                                <Link to="/admin-panel/p/privacy-policy" className="nav-link">
                                    <span className="pcoded-micon">
                                        <i className="feather icon-file-text"></i>
                                    </span>
                                    <span className="pcoded-mtext">Privacy Policy</span>
                                </Link>
                            </li>
                            <li data-username="form elements advance componant validation masking wizard picker select" className="nav-item">
                                <Link to="/admin-panel/p/terms-and-conditions" className="nav-link">
                                    <span className="pcoded-micon">
                                        <i className="feather icon-file-text"></i>
                                    </span>
                                    <span className="pcoded-mtext">Terms And Conditions</span>
                                </Link>
                            </li>
                            <li data-username="form elements advance componant validation masking wizard picker select" className="nav-item">
                                <Link to="/admin-panel/enquiry" className="nav-link">
                                    <span className="pcoded-micon">
                                        <i className="feather icon-file-text"></i>
                                    </span>
                                    <span className="pcoded-mtext">Enquiry</span>
                                </Link>
                            </li>

                            <li className="nav-item pcoded-menu-caption">
                                <label>Setting</label>
                            </li>
                            <li data-username="form elements advance componant validation masking wizard picker select" className="nav-item">
                                <Link to="/admin-panel/p/setting" className="nav-link">
                                    <span className="pcoded-micon">
                                        <i className="feather icon-settings"></i>
                                    </span>
                                    <span className="pcoded-mtext">Setting</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};
const mapStateToProps = state => {
    return {
        rtlLayout: state.rtlLayout,
        headerBackColor: state.headerBackColor,
        headerFixedLayout: state.headerFixedLayout,
        collapseMenu: state.collapseMenu
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onToggleNavigation: () => dispatch({ type: actionTypes.COLLAPSE_MENU }),
    }
};
export default Navigation;