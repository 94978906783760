import { Link, Navigate, Route, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from 'axios';


import { API_URL, authorization_key, base_url } from "../../../config/config";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const Pages = () => {
    let { slug } = useParams();
    function handleInputChange(event) {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        console.log(formData);
    }
    const [formData, setFormData] = useState({ page_title: '', page_tagline: '', page_description: '' });

    const navigate = useNavigate();
    const [token, setToken] = useState(localStorage.getItem("admin_token"));
    const [pageData, setPageData] = useState({});
    const [success, setSuccess] = useState('');

    const tokens = localStorage.getItem("admin_token")

    function decodeHtmlEntities(text) {
        const parser = new DOMParser();
        const decodedString = parser.parseFromString(`<!doctype html><body>${text}`, 'text/html').body.textContent;
        return decodedString;
    }
    const ACCESS_TOKEN = tokens;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `${ACCESS_TOKEN}`
    };
    const handlePageSubmit = async (e) => {
        e.preventDefault();
        const ACCESS_TOKEN = tokens;


        try {
            const response = await axios.put(API_URL+'admin/pages/'+slug, formData, { headers });
            console.log(response.data.status);
            if (response.data.status) {
                setSuccess(response.data.message);

            } else {
                setSuccess(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const handleGetPage = async (e) => {
        // e.preventDefault();

        try {
            const response = await axios.get(API_URL+ 'admin/pages/' + slug, { headers });
            // console.log(response.data.token);

            if (response.data) {
                setPageData(response.data.data);
                setFormData(response.data.data);
                // console.log(formData);

            } else {
                // console.log(response.data.token);
            }
            // Redirect to dashboard or any other page
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        handleGetPage(slug)
    }, [slug]);

    return (
        <>
            <div className="">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="page-header">
                                <div className="page-block">
                                    <div className="row align-items-center">
                                        <div className="col-md-12">
                                            <div className="page-header-title">
                                                <h5 className="m-b-10">{pageData.page_title}</h5>
                                            </div>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={'../'} ><i className="feather icon-home"></i></Link>
                                                </li>
                                                <li className="breadcrumb-item">Pages</li>
                                                <li className="breadcrumb-item">{pageData.page_title}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-body">
                                <div className="page-wrapper ">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5>{pageData.page_title}</h5>
                                                </div>
                                                <div className="card-body">
                                                    <h5>Update {pageData.page_title} Page</h5>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <form onSubmit={handlePageSubmit}>
                                                                <div className="form-group">
                                                                    <label for="PageTitle">Page Title</label>
                                                                    <input type="text" className="form-control" id="PageTitle" name="page_title" placeholder="Enter Page Title" onChange={handleInputChange} value={formData.page_title} autocomplete="on" />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label for="PageTagline">Page Tagline</label>
                                                                    <input type="text" className="form-control" id="PageTagline" name="page_tagline" placeholder="Enter Page Tagline" onChange={handleInputChange} value={formData.page_tagline} autocomplete="on" />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label for="PageDescription">Page Description</label>
                                                                    {/* <ReactQuill name="page_description" onChange={handleInputChange} value={formData.page_description} /> */}
                                                                    <textarea className="form-control" name="page_description" id="PageDescription" rows="7" placeholder="Enter Page Description" onChange={handleInputChange} value={formData.page_description}></textarea>
                                                                </div>
                                                                {success && <div class="alert alert-success" role="alert">{success}</div>}
                                                                <button type="submit" className="btn btn-primary">Update</button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Pages;
